// import consola from 'consola';
import consola from 'consola';

export default {
  async asyncData({$axios, app, route}) {
    // consola.log('pagemeta async');
    // let path = route.path;
    let json = await app.meta(route);
    return {
      metaJson: app.seo(json),
      // slug: path.substring(path.lastIndexOf("/") + 1),
    }
  },
  head() {
    return this.metaJson
  }
}
