
import {mapState} from 'vuex';

export default {
  data() {
    return {
      version: process.env.version,
      visFooter: this.$route.name !== 'tildeling'
    }
  },
  computed: {
    ...mapState({
      navigation: state => state.navigation.items.footer,
      globaler: state => state.globals
    }),
    home() {
      let url = '';
      switch (process.env.site) {
        case 'smn':
          url = "https://smn.no";
          break;
        case 'snn':
          url = "https://snn.no";
          break;
      }
      return url;
    }
  },
  watch: {
    $route() {
      this.visFooter = this.$route.name !== 'tildeling'
    }
  },
}
