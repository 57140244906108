import ScrollOut from "scroll-out";

export default {
  data() {
    return {
      so: null
    }
  },
  mounted() {
    if (!this.$preview) {
      // console.log('ScrollOut mounted');

      this.so = ScrollOut({
        // once: true,
        // scrollingElement: 'main',
        // threshold: 0.2,
        cssProps: {
          viewportY: true,
          scrollPercentY: true,
        }
      });
    }
  },
  destroyed() {
    if (!this.$preview) {
      this.so.teardown();
    }
  }
};
