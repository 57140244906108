
import meta from '@/plugins/page-meta.mixin.js';
import scrollOut from '@/plugins/scroll-out.mixin.js';
import LazyHydrate from 'vue-lazy-hydration';

export default {
  components: {
    LazyHydrate
  },
  mixins: [meta, scrollOut],
  async asyncData({$axios, error, payload, app, route}) {
    if (payload) {
      return {
        innhold: payload.data,
        metaJson: app.seo(payload.meta)
      };
    }

    // const meta = await app.meta(route);
    return await $axios.get("/api/side", {params: {slug: '__home__'}})
      .then(({data}) => {
        return {
          innhold: data.data,
          metaJson: app.seo(data.meta)
        }
      })
      .catch(e => {
        error({statusCode: e.response.status, message: 'Fant ikke forside'})
      });
  },
  watch: {},
  computed: {
    phone() {
      return !this.$screen.sm
    },
    kartBilder() {
      const site = process.env.site;
      return `${require(`@/assets/img/${site}/lite-kart.jpg`)} 480w, ${require(`@/assets/img/${site}/stort-kart.jpg`)} 2000w, ${require(`@/assets/img/${site}/storst-kart.jpg`)} 2880w`;
    },
    midtEn() {
      return this.innhold.midtseksjon.en || null;
    },
    midtTo() {
      return this.innhold.midtseksjon.to || null;
    },
    midtTre() {
      return this.innhold.midtseksjon.tre || null;
    }
  },
  mounted() {
    // ScrollOut({
    //   scrollingElement: 'main',
    //   cssProps: {
    //     scrollPercentY: true
    //   }
    // });
  },
  methods: {}
}
