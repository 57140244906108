import { render, staticRenderFns } from "./index.vue?vue&type=template&id=30fd6c7e&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=30fd6c7e&prod&lang=scss&scoped=true&"
import style1 from "./index.vue?vue&type=style&index=1&id=30fd6c7e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30fd6c7e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CtaButton: require('/workspace/frontend/components/CtaButton.vue').default,SvgBilde: require('/workspace/frontend/components/SvgBilde.vue').default,Footer: require('/workspace/frontend/components/Footer.vue').default})
